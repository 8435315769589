import restaurant from "./images/mamaliga.jpg";
import biserica from "./images/biserica1.jpg";
import img_card from "./images/picioruse.jpg";
import imgheadermb from "./images/f21m.jpg";
import imgheader from "./images/f2m.jpg";
import imgheadermiini from "./images/f4.jpg";
import imgheadermiinimb from "./images/f4.jpg";
import logo from './images/stea.png'



const data = {
    introData: [{
        copilul: "Matteo",
        familia: "fam. Graur",
        logo: logo,
        tata: "Vasile",
        mama: "Cristina",
        data: "18 Noiembrie 2022",
        data_confirmare: "10 noiembrie 2022",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "victorraboda@gmail.com", 
       tel: "+39 388 102 4314",
       phone: "tel:+393881024314",
       viber: "viber://chat?number=%2B393881024314",
       whatsapp: "https://wa.me/+393881024314",
       messenger: "https://www.messenger.com/t/graur.cristina.1",
       tel1: "+44 7941 477988",
       phone1: "tel:+447941477988",
       viber1: "viber://chat?number=%2B447941477988",
       whatsapp1: "https://wa.me/+447941477988",
       messenger1: "https://www.messenger.com/t/graur.cristina.1",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Biserica ortodoxă",
            name: "Chiesa ortodossa di San Demetrio",
            data: "18 noiembrie 2022, vineri, ora 15:00",
            adress: "Panni 167 Parco Amendola 41125 Modena",
            harta: "https://goo.gl/maps/Kq4hnEVEjPV5LMzT8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "MamaLiga",
            data: "18 noiembrie 2022, vineri, ora 18:00",
            adress: "Scuola di Ganaceto, 45, 41123 Lesignana MO",
            harta: "https://g.page/mamaliga-modena?share",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;